<!-- Order -->
<template>
  <div>
    <div slot="header" class="modal-header popup-header">
      <span class="modal-title-popup"
        ><i class="fa fa-credit-card" aria-hidden="true"></i
        >{{ label.title }}</span
      >
      <i
        style="float: right; padding: 3px 0"
        class="model-close el-icon-close icon-pointer"
        @click.prevent="closeForm"
      ></i>
    </div>
    <div class="card card-custom" v-loading="loading">
      <div class="card-body p-0">
        <!--begin: Orders-->
        <div
          class="wizard wizard-1"
          id="kt_wizard_v1"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Order Body-->
          <div class="row justify-content-center">
            <div class="col-xl-12">
              <!--begin: Orders Form-->
              <el-form
                ref="form"
                :model="form"
                auto-complete="false"
                style="padding: 0px !important"
              >
                <div class="row">
                  <div class="col-sm-4">
                    <el-form-item label="Authorized Person">
                      <template>
                        <el-select
                          clearable
                          filterable
                          v-model="form.user_id"
                          placeholder="Select"
                          style="width: 100%"
                          @change="handleValidation('user_error', form.user_id)"
                        >
                          <el-option
                            v-for="(user, index) in users"
                            :key="index"
                             :label="
                                    user.first_name +
                                    ' ' +
                                    (user.last_name == null
                                      ? ''
                                      : user.last_name)
                                  "
                            :value="user.id"
                            auto-complete="off"
                            style="height: auto; padding: 10px 20px"
                          >
                          </el-option>
                        </el-select>
                      </template>
                      <span class="form-err" v-if="form.errors.has('user_id') && !form.user_id ">The authorized person field is required.</span>
                    </el-form-item>
                    <el-form-item label="Add Credit" prop="credit_limit">
                      <el-input
                        prefix-icon="fas fa-dollar-sign"
                        v-model="form.credit_limit"
                        auto-complete="off"
                        :min="0" @change="checkAmount(form.credit_limit)"
                      ></el-input>
                      <span class="form-err"  v-if="form.errors.has('credit_limit') && !form.credit_limit">{{ form.errors.get("credit_limit") }}</span>
                      <span class="form-err" v-if="this.credit_error">{{this.credit_error}}</span>
                    </el-form-item>
                    <el-form-item label="Document Name">
                      <template>
                        <el-select
                          filterable
                          multiple
                          collapse-tags
                          v-model="form.document_name"
                          placeholder="Select"
                          style="width: 100%"
                          @change="addDocuments()"
                          :max="3"
                        >
                        <el-option class="add-container" value="">
                                  <a
                                    class="add-drop-btn"
                                    @click="CreateDocument()"
                                  >
                                    <i class="el-icon-circle-plus"></i>
                                    Add Document
                                  </a>
                         </el-option>
                          <el-option
                            v-for="(docs, index) in document_list"
                            :key="index"
                            :label="docs.document_name"
                            :value="docs.document_name"
                            auto-complete="off"
                            style="height: auto"
                          >
                          </el-option>
                        </el-select>
                      </template>
                    </el-form-item>
                  </div>
                  <div class="col-sm-8">
                    <div class="row">
                      <div class="col-xl-4 mt-6" v-for="(document,index) in form.upload_document" :key="index">
                        <div class="field">
                          <div class="preview-container">
                            <img
                              :src="document.document_image"
                              style="width: 150px; height: 200px"
                            />
                          </div>
                        </div>
                        <div class="field">
                          <div
                            class="upload-btn-container"
                            style="text-align: left; margin-top: 0px"
                          >
                            <a
                              href="#"
                              class="add-logo-btn"
                              style=" display: inline !important;margin-left: 14px;"
                            >
                              Add Document
                              <input
                                type="file"
                                @change="onDocFileChange($event, document.document_name)"
                                accept="image/gif, image/jpeg"
                              />
                            </a>
                            <div class="document_name">
                              {{document.document_name}}
                            </div>
                          </div>
                        </div>
                        <span class="form-err" v-if="document.document_error == false" style="text-align:center">*document required.</span>
                      </div>                      
                      </div>
                    </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <el-form-item  prop="term_id">
                      <el-checkbox
                        v-model="form.credit_approve"
                        :checked="form.credit_approve"
                        @change="handleValidation('credit_approve', form.credit_approve)"
                        >Credit Term</el-checkbox
                      >
                      <el-select
                        v-model="form.term_id"
                        placeholder="Select"
                        clearable
                        v-if="form.credit_approve == true"
                        @change="handleValidation('creditTerm_error', form.term_id)"
                      >
                        <el-option class="add-container" value="">
                          <a class="add-drop-btn" @click="CreateTerm()">
                            <i class="el-icon-circle-plus"></i> Add Term
                          </a>
                        </el-option>
                        <el-option
                          v-for="(term, index) in term_list"
                          :key="index"
                          :label="term.term_name+' days'"
                          :value="term.id"
                          auto-complete="off"
                        >
                        </el-option>
                      </el-select>
                      <span
                        class="form-err"
                        v-if="form.errors.has('term_id') && !form.term_id"
                        >The credit term field is required.</span
                      >
                    </el-form-item>
                  </div>
                </div>
                <div class="row" style="margin-top: 15px">
                  
                  <div class="col-sm-12">
                    <button
                      class="
                        rounded-0
                        btn btn-sm btn-primary
                        font-weight-bold
                        text-uppercase
                      "
                      @click.prevent="addCredit()"
                    >
                      {{ btn_text }}
                    </button>
                  </div>
                </div>
              </el-form>
              <!--end: Orders Form-->
            </div>
          </div>
          <!--end: Orders Body-->
        </div>
      </div>
      <!--end: Orders-->
    </div>
  </div>
</template>

<script>
import Form from "@/core/services/api.form.services";
import { globalEvents } from "@/utils/globalEvents";
import { getUsers, getDocumentList,getCustomerCredit } from "@/api/customer";
import CreateDocument from "@/components/CreateCustomer/document_type";
import CreateTerm from "@/components/CreateOrderTerm";
import { getOrderTerm } from "@/api/order";
export default {
  name: "add-order",
  props: {
    customer_id: {
      type: Number,
    },
    label: {
      type: Object,
      default() {
        return { button: "Create", title: "Add Customer Credit" };
      },
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      btn_text: "Add",
      form: new Form({
        customer_id: null,
        credit_limit: null,
        user_id: null,
        document_name: [],
        document_license: null,
        document_passport: null,
        document_health: null,
        document_other: null,
        credit_approve: false,
        term_id: null,
        upload_document : [],
      }),
      credit_list: [],
      document_list: [],
      api_url: "customer/addcredit/",
      users: [],
      credit:[],
      term_list:[],
      img_preview: "../../media/bg/profile-img.png",
      user_error: "",
      credit_error: "",
      document_error: "",
      document1_error: "",
      document2_error: "",
      document3_error: "",
      document4_error: "",
      creditTerm_error: ""
    };
  },
  components: {},
  mounted() {
    this.form.customer_id = this.customer_id;
    globalEvents.$on("DocumentType", (type) => {
      if (type) {
        this.document_list.push(type);
        this.form.document_name.push(type.document_name);
        this.addDocuments();
      }
    });
    globalEvents.$on("TermAdded", (term) => {
      if (term) {
        this.term_list.push(term);
        this.form.term_id = term.id;
      }
    });
  },
  methods: {
    // Selvan added
    handleValidation(fieldName,value) {
      if(fieldName ==='credit_approve' && value === false) {
        this.creditTerm_error = "";
      }else if(fieldName ==='credit_approve' && value === true && !this.form.term_id) {
        this.creditTerm_error = "The credit term field is required.";
      } else if(value) {
        this[fieldName] = "";
      }
    },
    // 
    closeForm() {
      if (!this.form.submiting) {
        this.$emit("close");
      }
    },
    addDocuments() {
      this.form.upload_document = [];
      this.form.document_name.map((doc)=>{
        if(doc){
        this.form.upload_document.push({
          'document_name' : doc,
          'document_image': '../../media/bg/profile-img.png',
          'image' :'',
          'document_error' : false
        })
      }
      });
     
    },
    getUsername() {
      this.loading = true;
      getUsers().then((response) => {
        this.users = response.data.data;
        this.loading = false;
      });
    },
    getDocumentList() {
      this.loading = true;
      getDocumentList().then((response) => {
        this.document_list = response.data.data;
        this.loading = false;
      });
    },
     getTermlist() {
      getOrderTerm().then((response) => {
        this.term_list = response.data.data;
      });
    },
     CreateTerm() {
      this.$modal.show(
        CreateTerm,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    getCustomerCredit() {
      this.loading = true;
      getCustomerCredit(this.customer_id).then((response) => {
        console.log(response);       
        this.credit = response.data.data;
        this.form.credit_approve = response.data.data.credit_approve
        this.loading = false;       
      });
    },
     checkAmount(amount){      
      this.credit_error = '';
      this.form.credit_approve = false;
      let due_amount = parseFloat(amount).toFixed(2).match(/^-?[0-9]*[.][0-9]+$/);
       if(due_amount != null){
        this.form.credit_limit = parseFloat(due_amount).toFixed(2);
        this.form.credit_approve = true;
        this.handleValidation('credit_error', due_amount);
      }else{        
        this.credit_error = 'Please Enter Amount Only'; 
        this.form.credit_limit = ''       
      }       
    },

    onDocFileChange(e, name) {
      let files = e.target.files || e.dataTransfer.files;
      if (files[0].size > 1024 * 1024) {
        e.preventDefault();
        const h = this.$createElement;
        this.$notify({
          message: h("i", { style: "color: red" }, "File too big (> 1MB)"),
          offset: 200,
          type: "warning",
        });

        return true;
      }
      if (files.length <= 0) {
        return;
      }
      this.form.upload_document.map(async(upload) =>{
        if(upload.document_name == name){
          upload.document_image = URL.createObjectURL(files[0]);
          upload.image = await this.readFile(files[0]);
          upload.document_error = true
        }
      })
    },
    readFile(file) {
      return new Promise((resolve) => {
        const fReader = new FileReader();
        fReader.onload = () => {
          resolve(fReader.result);
        };
        fReader.readAsDataURL(file);
      });
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (files.length <= 0) {
        return;
      }
      this.form.document_image = files[0];
      this.img_preview = URL.createObjectURL(files[0]);
    },
    addCredit: function () {
      let upload_error = false;
      this.form.upload_document.map((document)=>{
        if(document.document_error == false){
          upload_error = true;
        }
      })
      if(upload_error == false){
        this.loading = true;
              this.api_url = "customer/addcredits/" + this.form.customer_id;
              this.form
                .post(this.api_url)
                .then((response) => {
                  if (response.status) {
                    globalEvents.$emit('CreditAdded', 'success')
                    this.$emit("close");
                    this.$showResponse("success", response.message);
                  }
                  this.loading = false;
                })
                .catch((error) => {
                  console.log(error);
                  this.loading = false;
                });
      }
     
    },
    CreateDocument() {
      this.$modal.show(
        CreateDocument,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
  },

  created() {
    //console.log(this.inventory_id);

    this.getUsername();
    this.getDocumentList();
    this.getCustomerCredit();
    this.getTermlist();
  },
};
</script>

<style>
.vm--modal {
  overflow: unset !important;
}

.document_name {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}
</style>

